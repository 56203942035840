import React from 'react'

import AccordionItem from './AccordionItem'

export default function Accordion( props ) {

	const { accordionItem } = props.input

	return (
		<div className="accordion">
			<div className="accordion__container wrapper">
				{ accordionItem.map( ( node, index ) => (
					<AccordionItem data={ node } key={ index } />
				) ) }
			</div>
		</div>
	)
}