import React, { useState } from 'react'
import ReactMarkdown from "react-markdown"

export default function AccordionItem( { data } ) {

	const { question, answer } = data
	const [ active, setActive ] = useState()

	return (
		<div className={`accordion__item ${ active ? 'active' : '' }`}>
			<button
				className="accordion__selector"
				onClick={ () => setActive(!active) }
			>
				<div className="accordion__question">
					{ question }
				</div>

				<div className={`accordion__selector-icon-container ${ active ? 'active' : '' }`}>
					<div className="accordion__selector-icon accordion__selector-icon--vertical"></div>
					<div className="accordion__selector-icon accordion__selector-icon--horizontal"></div>
				</div>
			</button>
			<div className={`accordion__content-container ${ active ? 'active' : '' }`}>
				<div className="accordion__content">
					<ReactMarkdown source={ answer } className="accordion__description" />
				</div>
			</div>
		</div>
	)
}