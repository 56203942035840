import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

export default function AboutInfo ( props ) {

	const { aboutInfoTitle, aboutInfoDescription, aboutInfoReverse, aboutInfoImage, aboutInfoLinkUrl, aboutInfoLinkLabel, aboutInfoLinkExternal } = props.input

	return (
		<section className={`about-info ${ aboutInfoReverse ? 'about-info--alt' : '' } wrapper`}>
			<div className="about-info__wrapper">

				<div className="about-info__image-container">
					<Img className="about-info__image" fluid={ aboutInfoImage.imageFile.childImageSharp.fluid } />
				</div>

				<div className="about-info__info-container">
					{ aboutInfoTitle && <h3 className="about-info__title">{ aboutInfoTitle }</h3> }
					<ReactMarkdown
						className="about-info__description"
						escapeHtml={ false }
						source={ aboutInfoDescription }
					/>

					{ aboutInfoLinkUrl && aboutInfoLinkLabel &&
						<>
							{ aboutInfoLinkExternal ? (
								<a href={ aboutInfoLinkUrl } className="about-info__link" target="_blank" rel="noreferrer">
									{ aboutInfoLinkLabel }
								</a>
							) : (
								<Link to={ aboutInfoLinkUrl } className="about-info__link">
									{ aboutInfoLinkLabel }
								</Link>
							) }
						</>
					}
				</div>

			</div>
		</section>
	)
}
