import React from 'react'

import AboutInfo from './about/AboutInfo'
import Accordion from './common/Accordion'
import CTABanner from './common/CTABanner'
import HeroCarousel from './common/HeroCarousel'
import ImageWithLink from './common/ImageWithLink'
import Video from './common/Video'
import TrailInfo from './artTrail/TrailInfo'
import ArticleImage from './article/ArticleImage'
import ArticleQuote from './article/ArticleQuote'
import ArticleTextBlock from './article/ArticleTextBlock'

export default function DynamicZone( { components } ) {

	return (
		<>
			{ components.map(( comp, index ) => {
				switch (comp.__typename) {
					case 'StrapiQuery_ComponentCommonAboutInfo':
						return <AboutInfo key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArtTrailTrailInfo':
						return <TrailInfo key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleQuote':
						return <ArticleQuote key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleImage':
						return <ArticleImage key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleTextBlock':
						return <ArticleTextBlock key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonHeroCarousel':
						return <HeroCarousel key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonCallToActionBanner':
						return <CTABanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonAccordion':
						return <Accordion key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonImage':
						return <ImageWithLink key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonVideo':
						return <Video key={ index } input={ comp } />
					default:
						return null
				}
			}) }
		</>
	)
}