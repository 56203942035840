import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'
import PageHeader from '../components/PageHeader/PageHeader'

export default function AboutUsPage({ data: { strapi: { aboutUs } }, location }) {
	
	return (
		<>
			<SEO title="About Us" description={ aboutUs.seoDescription } pathname={ location.pathname } />

			<PageHeader heading="About Us" />

			{ aboutUs && aboutUs.content &&
				<DynamicZone components={ aboutUs.content } />
			}
		</>
	)
}

export const query = graphql`
	query aboutUsQuery {
		strapi {
			aboutUs {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonAboutInfo {
						id
						aboutInfoTitle
						aboutInfoDescription
						aboutInfoReverse
						aboutInfoLinkUrl
						aboutInfoLinkLabel
						aboutInfoLinkExternal
						aboutInfoImage {
							id
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										...GatsbyImageSharpFluid_withWebp_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonImage {
						id
						imageLinkUrl
						imageLinkLabel
						imageLinkExternal
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										aspectRatio
										...GatsbyImageSharpFluid_withWebp_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	}
`