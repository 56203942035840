import React, { useState, useEffect, useRef, useCallback } from 'react';

import HeroCarouselSlide from './HeroCarouselSlide'

export default function Carousel ( props ) {

	const images = props.input.heroImages
	const RESIZE_DEBOUNCE = 150
	const carouselRef = useRef()

	const getWidth = () => carouselRef.current.offsetWidth

	const isSingleImage = images.length === 1 ? true : false
	// Double up the images for infinite loop effect, mainly for the 2 images fallback (requires 3 min otherwise)
	const imageSlides = isSingleImage ? images : [ ...images, ...images ]
	const totalImages = imageSlides.length

	const [ currentSlide, setCurrentSlide ] = useState( 0 )
	const [ width, setWidth ] = useState( 0 )

	useEffect( () => {

		if ( carouselRef ) {
			setWidth( carouselRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId )
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE )
		};

		// set resize listener
		window.addEventListener( 'resize', resizeListener )

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener )
		}
	}, [] )

	/**
	 * Allows images to infinite loop when incremented/numbers are negative, image at correct positive index returned
	 * @param {Number} imageIndex - images at index
	 * @param {Number} totalImages -- total number of images
	 */
	const modulus = ( imageIndex, totalImages ) => {
		return ( ( imageIndex % totalImages ) + totalImages ) % totalImages
	}

	const handleButtonClick = useCallback( increment => {
		setCurrentSlide( modulus( currentSlide + increment, totalImages ) )
	}, [ currentSlide, totalImages ])

	useEffect(() => {
		if ( isSingleImage ) return

		// Add 5 second timer to the slider
		const timer = setTimeout( () => handleButtonClick( 1 ), 5000)
		return () => clearTimeout( timer );

	}, [ handleButtonClick, isSingleImage ])

	return (
		<div className="hero-carousel">
			<div
				ref={ carouselRef }
				className="hero-carousel__slider-container"
				style={{ height: `${ width / 3 }px` }}
			>
				{ imageSlides.map ( ( slide, index ) => (
					<HeroCarouselSlide slide={ slide } slideIndex={ index } currentSlide={ currentSlide } totalImages={ totalImages } key={ index } />
				) ) }
			</div>
		</div>
	)
}