import React from 'react'
import PropTypes from 'prop-types'

export default function ArticleQuote( props ) {

	const data = props.input

	return (
		<section className="article-quote">
			<div className="article-quote__wrapper wrapper">
				<div className="article-quote__container">
					{ data.quote &&
						<div className="article-quote__quote">
							"{ data.quote }"
						</div>
					}

					{ data.author &&
						<span className="article-quote__author">{ data.author }</span>
					}
				</div>
			</div>
		</section>
	)
}

ArticleQuote.propTypes = {
	input: PropTypes.object.isRequired
};