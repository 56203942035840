import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

export default function ImageWithLink ( props ) {

	const { image, imageLinkUrl, imageLinkLabel, imageLinkExternal } = props.input

	const isPortrait = image.imageFile.childImageSharp.fluid.aspectRatio <= 1

	return (
		<section className={`image-link wrapper ${ isPortrait ? 'image-link--portrait' : '' }`}>
			<div className="image-link__wrapper">

				<Img className="image-link__image" fluid={ image.imageFile.childImageSharp.fluid } />

				{ imageLinkUrl && imageLinkLabel &&
					<>
						{ imageLinkExternal ? (
							<a href={ imageLinkUrl } className="image-link__link" target="_blank" rel="noreferrer">
								{ imageLinkLabel }
							</a>
						) : (
							<Link to={ imageLinkUrl } className="image-link__link">
								{ imageLinkLabel }
							</Link>
						) }
					</>
				}
			</div>
		</section>
	)
}