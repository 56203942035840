import React from 'react'
import Img from 'gatsby-image'

export default function ArticleImage ( props ) {

	const { image, title } = props.input

	return (
		<section className="article-image">
			<div className="article-image__wrapper wrapper">
				<Img className="article-image__image" fluid={ image.imageFile.childImageSharp.fluid } />
				{ title && <span className="article-image__title">{ title }</span> }
			</div>
		</section>
	)
}
