import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default function TrailInfo ( props ) {

	const { trailInfoIntro, trailInfoTitle, trailInfoImage, trailInfoLinkUrl, trailInfoLinkLabel, trailInfoLinkExternal } = props.input

	return (
		<section className="trail-info">
			<div className="trail-info__wrapper wrapper">
				{ trailInfoImage &&
					<div className="trail-info__image-container">
						<Img className="trail-info__image" fluid={ trailInfoImage.imageFile.childImageSharp.fluid } />
					</div>
				}
				<div className="trail-info__text-container">
					{ trailInfoTitle && <h3 className="trail-info__title">{ trailInfoTitle }</h3> }
					{ trailInfoIntro &&
						<ReactMarkdown
							className="trail-info__description"
							escapeHtml={ false }
							source={ trailInfoIntro }
						/>
					}
					<div className="trail-info__link-container">
						{ trailInfoLinkUrl && trailInfoLinkLabel &&
							<>
								{ trailInfoLinkExternal ? (
									<a href={ trailInfoLinkUrl } className="trail-info__link" target="_blank" rel="noreferrer">
										{ trailInfoLinkLabel }
									</a>
								) : (
									<Link to={ trailInfoLinkUrl } className="trail-info__link">
										{ trailInfoLinkLabel }
									</Link>
								) }
							</>
						}
					</div>
				</div>
			</div>
		</section>
	)
}
