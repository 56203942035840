import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function ArticleTextBlock ( props ) {

	const { text } = props.input

	return (
		<div className="text-block">
			<div className="text-block__wrapper wrapper">
				<ReactMarkdown
					className="text-block__body"
					escapeHtml={ false }
					source={ text }
				/>
			</div>
		</div>
	)
}
